<template>
  <div class="select-setter" :class="{ disabled: !enabled }">
    <div class="title">
      <span>{{ onLang(data.title) }}</span>
      <TooltipElement :content="onLang(data.tooltip)" alwaysShow placement="bottom">
        <img src="@/assets/icons/Info_24.svg" alt="">
      </TooltipElement>
    </div>
    <div class="select">
      <div class="selected-item" @click="isOpen = !isOpen">
        {{ onLang(selectedItem.label) }}
        <img :class="{ 'arrow-up': isOpen }" src="@/assets/icons/triangle-yellow.svg" alt="">
      </div>
      <div v-show="isOpen" class="options">
        <div 
          v-for="item in data.options" 
          :key="item.value" 
          class="option"
          :class="{ active: item.value === value }"
          @click="selectValue(item.value)"
        >
          {{ onLang(item.label) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSetter',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String, Boolean ],
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    selectedItem() {
      return this.data.options.find(item => item.value === this.value)
    }
  },
  methods: {
    onLang(str) {
      if (str && String(str).includes('$t_')) {
        return this.$t(str.replace('$t_', ''))
      } else {
        return str
      }
    },
    selectValue(value) {
      if (this.enabled) {
        this.$emit('input', value)
      }
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.select-setter {
  background: #39425D;
  border-radius: 8px;
  padding: 8px 12px;
  position: relative;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 8px;
    span {
      font-size: px2rem(16);
      line-height: 24px;
      color: #ffffff;
    }
    img {
      width: 20px;
      height: 20px;
      @include filter_FFF_50;
      cursor: pointer;
    }
  }

  .select {
    position: relative;
    .selected-item {
      position: relative;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: px2rem(16);
      line-height: 24px;
      border: 1px solid #ffffff80;
      border-radius: 8px;
      cursor: pointer;

      img {
        position: absolute;
        right: 12px;
        transition: all 0.3s ease-in-out;

        &.arrow-up {
          transform: rotate(180deg);
        }
      }
    }

    .options {
      width: 100%;
      position: absolute;
      top: calc(100% + 4px);
      color: #ffffff;
      background: #151B35;
      border: 1px solid #4A5C78;
      border-radius: 8px;
      overflow: hidden;
      text-align: center;
      z-index: 2;

      .option {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(16);
        line-height: 24px;
        cursor: pointer;

        &:hover {
          background: #39425D;
          // color: #282942;
        }
        &.active {
          background: #4A5C78;
          // color: #282942;
        }
      }
    }
  }
}
</style>